<template>
  <a-card title="创建欣赏歌曲">
    <template #extra><a @click="handleBack">返回</a></template>
    <enjoy-song-form
      :actionType="FormActionType.CREATE"
      :current="{}"
      @onSubmit="handleCreate"
    ></enjoy-song-form>
  </a-card>
</template>

<script>
import EnjoySongForm from './EnjoySongForm.vue'
import { FormActionType } from './config'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import {
  createOfficialEnjoySong,
  setOfficialEnjoySongTags,
  createPersonalEnjoySong,
  setPersonalEnjoySongTags
} from '@/services/songs/index'

export default {
  components: { EnjoySongForm },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const loading = ref(false)

    const handleCreate = async (params, tagList) => {
      let createSuccess = false
      let id
      try {
        loading.value = true
        const createService = route.params.type === '1' ? createOfficialEnjoySong : createPersonalEnjoySong
        const data = await createService(params)
        id = data.id
        createSuccess = true
      } catch (error) {
        message.error('创建歌曲失败')
        loading.value = false
      }
      if (!createSuccess) {
        return
      }
      try {
        const setTagService = route.params.type === '1' ? setOfficialEnjoySongTags : setPersonalEnjoySongTags
        await setTagService(id, { tags: tagList })
        createSuccess = true
      } catch (error) {
        message.error('设置歌曲标签失败,请重新设置歌曲标签')
        loading.value = false
        handleBack()
      }
      if (createSuccess) {
        message.success('保存成功')
        loading.value = false
        handleBack()
      }
    }

    const handleBack = () => {
      history.go(-1)
    }
    return {
      FormActionType,
      handleBack,
      handleCreate
    }
  }
}
</script>

<style>
</style>
